import { useEffect, useState } from "react";
import "./restaurantadminpanel.css"; // Import the CSS file for styling
import DishGrid from "./DishGrid";
import DishForm from "./DishForm"; // Import DishForm
import {
  getRequest,
  postRequest,
  putRequest,
} from "../Authentication/AxiosInstance";
import Sidebar from "./SideBar";

const RestaurantAdminPanel = () => {
  const [restaurantMenu, setRestaurantMenu] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDish, setCurrentDish] = useState(null); // To store the clicked dish

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await getRequest(`/restaurant/menu`);
        setRestaurantMenu(response?.menu || []); // Update the menu state
      } catch (error) {
        console.error("Error fetching menu:", error);
      }
    };

    fetchMenu();
  }, []);

  const handleDishClick = (dish) => {
    setCurrentDish(dish); // Set the clicked dish
    setIsModalVisible(true); // Open the modal for editing
  };

  const handleAddNewClick = () => {
    setCurrentDish(null); // Clear the selected dish to open an empty form
    setIsModalVisible(true); // Open the modal for adding a new dish
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setCurrentDish(null); // Clear the selected dish
  };

  const handleFormSubmit = async (formValues) => {
    const formData = new FormData();

    for (const key in formValues) {
      if (key === "tags" && Array.isArray(formValues[key])) {
        // Append each tag individually
        formValues[key].forEach((tag) => {
          formData.append("tags", tag);
        });
      } else {
        formData.append(key, formValues[key]);
      }
    }

    try {
      if (currentDish) {
        formData.append("id", currentDish.id); // Append the dish ID for editing
        await putRequest(`/restaurant/fooditem/`, formData, true); // Update dish
      } else {
        await postRequest("/restaurant/newfooditem/", formData, true); // Add new dish
      }

      // Refresh the menu after the update
      const updatedMenu = await getRequest(`/restaurant/menu`);
      setRestaurantMenu(updatedMenu?.menu || []);
    } catch (error) {
      console.error("Error saving dish:", error);
    }
    handleModalClose(); // Close modal after submission
  };

  return (
    <div className="admin-panel">
      <div className="admin-sidebar">
        <Sidebar />
      </div>
      <div className="admin-content">
        <div className="page-header">Manage Dishes</div>
        <DishGrid
          dishes={restaurantMenu}
          onDishClick={handleDishClick}
          onAddNewClick={handleAddNewClick} // Add handler for adding new dish
        />
        {/* Render DishForm whenever the modal is visible */}
        {isModalVisible && (
          <DishForm
            onSubmit={handleFormSubmit}
            initialValues={currentDish} // Use currentDish for editing or null for adding new
            isModalVisible={isModalVisible}
            handleModalClose={handleModalClose}
          />
        )}
      </div>
    </div>
  );
};

export default RestaurantAdminPanel;
