import React, { useState, useEffect, useContext } from "react";
import "../Styles/home.css";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getRequest } from "../Authentication/AxiosInstance";
import { RestaurantContext } from "../Restaurantpage/RestaurantContext";
import whiteLogo from "../../Images/revore/whiteLogo.svg";

const Home = () => {
  const [longitude, setLongitude] = useState("0.0");
  const [latitude, setLatitude] = useState("0.0");
  const [nearestRestaurants, setNearestRestaurants] = useState([]);
  const [allRestaurants, setAllRestaurants] = useState([]);
  const { setRestaurantDetails } = useContext(RestaurantContext);
  const navigate = useNavigate();

  const fetchNearestRestaurants = async () => {
    try {
      const data = await getRequest("/restaurant/view/nearest", {
        longitude,
        latitude,
      });
      setNearestRestaurants(data.nearest_restaurants);
    } catch (error) {
      console.error("Fetching Nearest Restaurants Failed", error);
    }
  };

  const fetchAllRestaurants = async () => {
    try {
      const data = await getRequest("/restaurant/view/all");
      return data;
    } catch (error) {
      console.error("Fetching All Restaurants Failed", error);
    }
  };

  const {
    data: allRestaurantsData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["allRestaurants"],
    queryFn: fetchAllRestaurants,
  });

  useEffect(() => {
    fetchNearestRestaurants();
  }, [longitude, latitude]);

  useEffect(() => {
    if (allRestaurantsData) {
      setAllRestaurants(allRestaurantsData);
    }
  }, [allRestaurantsData]);

  const handleOnSelectRestaurant = (item) => {
    setRestaurantDetails({
      id: item.id,
      name: item.restaurant_name,
      address: item.restaurant_address,
      image: item.image,
    });
    navigate(`/restaurant?id=${item.id}`);
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>An error has occurred: {isError.message}</p>;

  return (
    <div className="homepage">
      <div className="logo">
      <img src={whiteLogo} style={{ width: '30%' }}></img>
      </div>
      <ReactSearchAutocomplete
      className="search"
        items={allRestaurantsData?.restaurants || []}
        fuseOptions={{ keys: ["restaurant_name", "restaurant_address"] }}
        resultStringKeyName="restaurant_name"
        onSelect={handleOnSelectRestaurant}
        styling={{
          backgroundColor: "transparent",
          border: "3px solid var(--Foundation-Orange-orange-50)",
          fontFamily: "Inter",
          fontSize: "12px",
          iconColor: "var(--Foundation-Orange-orange-200)",
        }}
      />
      {nearestRestaurants && (
        <>
          <p className="suggestion">Suggestions based on current location</p>
          <div className="restaurant">
            {Object.keys(nearestRestaurants).map((index) => (
              <div
                key={index}
                onClick={() => handleOnSelectRestaurant(nearestRestaurants[index])}
              >
                {nearestRestaurants[index].restaurant_name}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
