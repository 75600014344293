import React, { useEffect, useState } from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import ClickButton from "../Utilities/Function/ClickButton";
import PreparationCard from "./PreparationCard/PreparationCard";
import { Tooltip, Button } from "antd"; // Import Tooltip from Ant Design
import { HeartOutlined, TagsOutlined, SoundOutlined } from "@ant-design/icons"; // Ant Design Icons
import volumeIcon from "../../Images/icons/volume.png";
import heart from "../../Images/heart.png";
import filledheart from "../../Images/filledheart.png";
import hotIcon from "../../Images/icons/hot.png";
import coffeeIcon from "../../Images/icons/coffee.png";
import tagIcon from "../../Images/icons/wheat.png";
import grainIcon from "../../Images/icons/wheat.png";
import coldIcon from "../../Images/icons/cold.png";
import nutsIcon from "../../Images/icons/nuts.png";
import dairyIcon from "../../Images/icons/dairy.png";
import leavesIcon from "../../Images/icons/leaves.png";
import "../Styles/menu.css";

// Tag to icon mapping
const tagIcons = {
  Hot: hotIcon,
  Beans: coffeeIcon,
  Tea: leavesIcon,
  Grain: grainIcon,
  Cold: coldIcon,
  Dairy: dairyIcon,
  Nuts: nutsIcon,
  Energy: tagIcon,
};

const AutoplaySlider = withAutoplay(AwesomeSlider);

const ItemCard = ({
  item,
  speak,
  handleToggleWishlist,
  toggleTagDisplay,
  showTagName,
  wishlist,
  index,
  isLastItem,
}) => {
  const [showHints, setShowHints] = useState(false);
  const [currentHint, setCurrentHint] = useState("wishlist"); // Track the current hint

  useEffect(() => {
    const visited = localStorage.getItem("hasVisited");
    if (!visited) {
      setShowHints(true);
      localStorage.setItem("hasVisited", "true"); // Mark as visited
    }
  }, []);

  const handleNextHint = () => {
    if (currentHint === "wishlist") {
      setCurrentHint("tags");
    } else if (currentHint === "tags") {
      setCurrentHint("speech");
    } else {
      setShowHints(false); // End the hints after the last one
    }
  };

  return (
    <div>
      <div className="item">
        <div className="header">
          <div className="itemname">{item.fooditem_name}</div>

          {/* Tooltip for the Speech button */}
          <Tooltip
            title="Click to hear the pronunciation!"
            visible={showHints && currentHint === "speech"}
            onVisibleChange={handleNextHint}
            placement="top"
            overlayInnerStyle={{ backgroundColor: "#f7dfb0", color: "#000" }} // Custom background and text color
          >
            <button
              onClick={() => speak({ text: item.fooditem_pronounce })}
              className="speak-button"
            >
              <img src={volumeIcon} alt="Speak" />
            </button>
          </Tooltip>

          {/* Tooltip for Food item tags */}
          <Tooltip
            title="Click on the tags to see details!"
            visible={showHints && currentHint === "tags"}
            onVisibleChange={handleNextHint}
            placement="top"
            overlayInnerStyle={{ backgroundColor: "#f7dfb0", color: "#000" }} // Custom background and text color
          >
            <div className="tags-container">
              {item.tags &&
                item.tags.map((tag, idx) => (
                  <ClickButton
                    key={idx}
                    height="25px"
                    width={
                      showTagName[`${item.id}-${tag}`]
                        ? `${tag.length * 8 + 10}px`
                        : "25px"
                    }
                    backgroundColor="var(--Foundation-Orange-orange-100)"
                    icon={
                      showTagName[`${item.id}-${tag}`] ? null : tagIcons[tag]
                    } // Use correct icon
                    name={showTagName[`${item.id}-${tag}`] ? tag : ""}
                    fontColor="black"
                    iconWidth="15px"
                    iconHeight="15px"
                    handleClick={() => toggleTagDisplay(item.id, tag)}
                  />
                ))}
            </div>
          </Tooltip>

          <div className="itemprice">{item.fooditem_price}</div>
        </div>
        <div className="itemimage-slider">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={true}
            interval={3000}
            bullets={false}
            style={{ height: "100%", width: "100%" }}
          >
            <div className="itemimage-card-holder">
              <img
                className="itemimage-card"
                src={item.fooditem_image}
                alt={item.fooditem_name}
                style={{
                  height: "240px",
                  width: undefined,
                  aspectRatio: 2,
                }}
              />
            </div>
            <div className="preparation">
              <PreparationCard details={item.fooditem_preparation} />
            </div>
          </AutoplaySlider>

          {/* Tooltip for the Wishlist button */}
          <Tooltip
            title="Click to add to Wishlist!"
            visible={showHints && currentHint === "wishlist"}
            onVisibleChange={handleNextHint}
            placement="top"
            overlayInnerStyle={{ backgroundColor: "#f7dfb0", color: "#000" }} // Custom background and text color
          >
            <div className="itemquantity-holder">
              <ClickButton
                height="40px"
                width="40px"
                backgroundColor="var(--Foundation-Orange-orange-200)"
                icon={wishlist[item.id] ? filledheart : heart}
                handleClick={() => handleToggleWishlist(item)}
              />
            </div>
          </Tooltip>
        </div>
        <div className="footer">
          <div className="itemdescription">{item.fooditem_description}</div>
        </div>
      </div>

      {/* Add separator line if this is not the last item in the category */}
      {!isLastItem && <div className="itemline"></div>}
    </div>
  );
};

export default ItemCard;
