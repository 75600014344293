// src/Components/NavigationBar/NavigationBar.jsx
import React, { useState, useContext } from "react";
import "../Styles/navigationbar.css"; // Assuming the CSS file is in the same directory
import { useLocation } from "react-router-dom";
import Options from "./Options.jsx";
import TableNavigationView from "../TableNavigationView/TableNavigationView";
import { RestaurantContext } from "../Restaurantpage/RestaurantContext.jsx"; // Import the context

function Navbar({ className, showRestaurantName }) {
  const [menuNavigation, setMenuNavigation] = useState(false); // Initial closed state
  const [tableNavigation, setTableNavigation] = useState(false);
  const location = useLocation();
  const { restaurantDetails } = useContext(RestaurantContext); // Use context

  const useHomePageStyles =
    location.pathname === "/home" ||
    location.pathname === "/login" ||
    location.pathname === "/register";
  let useRestaurantPageStyles = location.pathname === "/restaurant";
  if (showRestaurantName !== undefined) {
    useRestaurantPageStyles = !showRestaurantName;
  }

  const toggleTableNavigationView = () => {
    setTableNavigation(!tableNavigation);
    console.log("Navigation Clicked");
  };

  const toggleMenu = () => {
    setMenuNavigation(!menuNavigation);
  };

  return (
    <div className="">
      {/* {tableNavigation && (
        <TableNavigationView toggleNavView={toggleTableNavigationView} />
      )} */}
      {menuNavigation && (
        <Options toggleMenuView={toggleMenu} />
      )}
      <div className={className === undefined ? "navbar" : className}>
        {!useHomePageStyles && !useRestaurantPageStyles && restaurantDetails && (
          <div
            className="navbar-location"
            onClick={() => {
              toggleTableNavigationView();
            }}
          >
            <div className="navbar-location-name">
              @ {restaurantDetails.name}
            </div>
          </div>
        )}
        {!useHomePageStyles && (
          <div
            className={`navbar-options ${
              menuNavigation ? "navbar-active" : ""
            }`}
            onClick={toggleMenu}
          >
            <div className="navbar-options-bar1"></div>
            <div className="navbar-options-bar2"></div>
            <div className="navbar-options-bar3"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
