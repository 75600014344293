import React, { createContext, useRef, useState, useEffect } from "react";
import {
  readFromLocalStorage,
  writeToLocalStorage,
} from "../Utilities/LocalStorage/localStorageUtils";
import { getRequest } from "../Authentication/AxiosInstance";

export const RestaurantContext = createContext();

const fetchMenu = async (restaurantId) => {
  try {
    const response = await getRequest(`/user/menu`, {
      id: restaurantId,
    });
    return response?.menu || [];
  } catch (error) {
    console.error("Error fetching menu:", error);
    return [];
  }
};

const fetchRestaurantDetails = async (restaurantId) => {
  try {
    const response = await getRequest(`/restaurant/view`, {
      id: restaurantId,
    });
    return response?.restaurant || {};
  } catch (error) {
    console.error("Error fetching restaurant details:", error);
    return {};
  }
};

export const RestaurantProvider = ({ children }) => {
  const menuRef = useRef(null);
  const [restaurantDetails, setRestaurantDetails] = useState({});
  const [restaurantMenu, setRestaurantMenu] = useState([]);

  const fetchRestaurantData = async (restaurantId) => {
    try {
      const details = await fetchRestaurantDetails(restaurantId);
      setRestaurantDetails({
        id: details.id,
        name: details.restaurant_name,
        address: details.restaurant_address,
        image: details.image,
      });

      const menu = await fetchMenu(restaurantId);
      setRestaurantMenu(menu);
    } catch (error) {
      console.error("Error fetching restaurant data:", error);
    }
  };

  useEffect(() => {
    if (restaurantDetails.id) {
      writeToLocalStorage("restaurantDetails", restaurantDetails);
    }
  }, [restaurantDetails]);

  return (
    <RestaurantContext.Provider
      value={{
        menuRef,
        restaurantDetails,
        setRestaurantDetails,
        restaurantMenu,
        setRestaurantMenu,
        fetchRestaurantData,
      }}
    >
      {children}
    </RestaurantContext.Provider>
  );
};
