import { useState } from "react";
import { Form, Input, InputNumber, Button, Upload, Modal, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ItemCard from "../Restaurantpage/ItemCard"; // Import ItemCard for preview

const DishForm = ({
  onSubmit,
  initialValues = {},
  isModalVisible,
  handleModalClose,
}) => {
  const [formValues, setFormValues] = useState(initialValues); // For real-time preview
  const [fileList, setFileList] = useState([]); // To handle uploaded files

  const handleValuesChange = (changedValues, allValues) => {
    setFormValues(allValues); // Update form values for real-time preview
  };

  const handleFileChange = ({ fileList }) => {
    setFileList(fileList); // Update the file list when user uploads a file
  };

  const handleFormSubmit = () => {
    const updatedValues = { ...formValues };

    if (fileList.length > 0) {
      updatedValues.fooditem_image = fileList[0].originFileObj;
    } else {
      // Remove 'fooditem_image' from updatedValues if no new image is uploaded
      delete updatedValues.fooditem_image;
    }

    // Ensure fooditem_rating is passed as a number
    if (
      updatedValues.fooditem_rating &&
      typeof updatedValues.fooditem_rating !== "number"
    ) {
      updatedValues.fooditem_rating = Number(updatedValues.fooditem_rating);
    }

    onSubmit(updatedValues); // Pass form values to the parent component for submission
  };

  return (
    <Modal
      title={
        initialValues
          ? `Edit Dish: ${initialValues?.fooditem_name}`
          : "Add New Dish"
      }
      visible={isModalVisible}
      onCancel={handleModalClose}
      footer={null}
      width={1000}
    >
      <div style={{ display: "flex", gap: "20px", flexDirection: "row" }}>
        <div style={{ flex: 1 }}>
          <Form
            onFinish={handleFormSubmit}
            initialValues={initialValues}
            onValuesChange={handleValuesChange}
          >
            <Form.Item
              name="fooditem_name"
              label="Dish Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="fooditem_category"
              label="Category"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item name="fooditem_description" label="Description">
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </Form.Item>

            <Form.Item
              name="fooditem_price"
              label="Price"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} step={0.01} />
            </Form.Item>

            <Form.Item name="fooditem_pronounce" label="Pronunciation">
              <Input />
            </Form.Item>

            <Form.Item name="fooditem_preparation" label="Preparation">
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
            </Form.Item>

            <Form.Item name="tags" label="Tags">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Add tags"
                defaultValue={initialValues?.tags || []}
              />
            </Form.Item>

            {/* Image URL or Upload */}
            <Form.Item name="fooditem_image" label="Image URL">
              <Input placeholder="Enter image URL or use the upload button" />
            </Form.Item>

            <Form.Item label="Upload New Image">
              <Upload
                fileList={fileList}
                beforeUpload={() => false} // Prevent automatic upload
                onChange={handleFileChange} // Handle file changes
                listType="picture"
              >
                <Button icon={<UploadOutlined />}>Upload New Image</Button>
              </Upload>
            </Form.Item>

            <Form.Item name="fooditem_rating" label="Rating">
              <InputNumber min={0} max={5} step={0.1} defaultValue={0} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div
          style={{
            flex: 1,
            borderLeft: "1px solid #f0f0f0",
            paddingLeft: "20px",
          }}
        >
          <h3>Preview</h3>
          {formValues && (
            <ItemCard
              item={formValues}
              speak={() => {}} // Disable speak functionality in preview
              selectedVoice={null}
              handleToggleWishlist={() => {}} // No wishlist handling in preview
              toggleTagDisplay={() => {}} // No tag display toggle in preview
              showTagName={{}} // No tags for the preview
              wishlist={{}} // Empty wishlist for preview
              isLastItem={true} // Treat it as the last item for layout
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DishForm;
